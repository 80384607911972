export default {
  industries(state) {
    return state.industries;
  },
  specializations(state) {
    return state.specializations;
  },
  positions(state) {
    return state.positions;
  },
};
