import { useToast } from "vue-toastification";

import api from "../../../api/index.js";
import router from "../../../router.js";

const toast = useToast();

export default {
  async verifyEmail(context, payload) {
    const { authService } = api;
    const response = await authService.verifyEmail(payload);

    if (response.status === 200) {
      localStorage.setItem("verified", 1);
      context.commit("setVerification", localStorage.getItem("verified"));
      router.push("/");
      toast.success("Email has been succesfully verified");
    } else {
      toast.error("Failed to verify email");
    }
  },
  async login(context, payload) {
    const { authService } = api;
    const response = await authService.login(payload);

    if (response.status === 200) {
      const { data } = response.data;

      localStorage.setItem("token", data.token);
      localStorage.setItem("id", data.id);
      localStorage.setItem("verified", data.verify === true ? 1 : 0);
      localStorage.setItem("role", data.role);
      if (data.CompanyId)
        localStorage.setItem(
          "companyVerified",
          data?.Company?.verify ? true : false
        );
      context.commit("setAuth", true);
      context.commit("setVerification", localStorage.getItem("verified"));
      if (data?.Company?.verify === false) {
        router.push("/company-verify");
        return true;
      }
      if (data.verify) {
        if (router.currentRoute.value.path === "/") router.go();
        else router.push("/");

        return true;
      } else {
        router.push("/verify-email");
        return true;
      }
    } else {
      const { errorMessage } = response.data;
      let message = errorMessage;

      if (errorMessage === "Your Company is deleted")
        message =
          "Your company is no longer available, please contact the administrator for further information";

      toast.error(message);
      return false;
    }
  },
  logout(context) {
    const role = localStorage.getItem("role") ?? "user";

    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("verified");
    localStorage.removeItem("companyVerified");
    localStorage.removeItem("role");

    context.commit("setAuth", false);
    context.commit("setVerification", 0);
    context.commit("user/setUser", {}, { root: true });

    if (role !== "user" && router.currentRoute.value.path === "/") router.go();
    else router.push("/");
  },
  async forgotPassword(context, payload) {
    const { authService } = api;
    const response = await authService.forgotPassword(payload);

    if (response.status === 200) {
      toast.success("Verification code sent");
      return true;
    } else {
      const { errorMessage } = response.data;
      toast.error(errorMessage);
      return false;
    }
  },
  async changePassWithVerifyCode(context, payload) {
    const { authService } = api;
    const response = await authService.changePassWithVerifyCode(payload);

    if (response.status === 200) {
      toast.success("Password changed");
      return true;
    } else {
      const { errorMessage } = response.data;
      toast.error(errorMessage);
      return false;
    }
  },
  async changePassword(context, payload) {
    const { authService } = api;
    const response = await authService.changePassword(payload);

    if (response.status === 200) {
      toast.success("Password successfully changed");
      return true;
    } else {
      toast.error("Failed to change password");
      return false;
    }
  },
};
