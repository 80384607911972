import axios from "./axios.js";

const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
const service = "industry";

export const getIndustries = () => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}`;
  return axios.post(url, { data: {} });
};

export const getSpecializations = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/specialization`;
  return axios.post(url, { data });
};

export const getPositions = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/position`;
  return axios.post(url, { data });
};
