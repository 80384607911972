import api from "../../../api/index.js";

export default {
  async addWorkExperience(context, payload) {
    const { workExperienceService } = api;
    const response = await workExperienceService.createWorkExperience(payload);

    return response.status === 200;
  },
  async updateWorkExperience(context, payload) {
    const { workExperienceService } = api;
    const response = await workExperienceService.updateWorkExperience(payload);

    return response.status === 200;
  },
  async deleteWorkExperience(context, payload) {
    const { workExperienceService } = api;
    const response = await workExperienceService.deleteWorkExperience(payload);

    return response.status === 200;
  },
};
