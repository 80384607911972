import { createStore } from "vuex";

import rootMutations from "./mutations.js";
import rootActions from "./actions.js";
import rootGetters from "./getters.js";

import userModule from "./modules/user/index.js";
import authModule from "./modules/auth/index.js";
import resumeModule from "./modules/resume/index.js";
import workExperienceModule from "./modules/workExperience/index.js";
import industryModule from "./modules/industry/index.js";
import jobPostModule from "./modules/jobPost/index.js";
import jobApplicationModule from "./modules/jobApplication/index.js";
import companyModule from "./modules/company/index.js";
import paymentModule from "./modules/payment/index.js";

const store = createStore({
  modules: {
    user: userModule,
    auth: authModule,
    resume: resumeModule,
    workExperience: workExperienceModule,
    industry: industryModule,
    jobPost: jobPostModule,
    jobApplication: jobApplicationModule,
    company: companyModule,
    payment: paymentModule
  },
  state() {
    return {};
  },
  mutations: rootMutations,
  getters: rootGetters,
  actions: rootActions,
});

export default store;
