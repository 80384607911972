/* export const encrypt = (password) => {
  const algorithm = process.env.VUE_APP_PASSWORD_ALGORITHM;
  const secretKey = process.env.VUE_APP_PASSWORD_SECRET_KEY;
  const iv = crypto.randomBytes(16);
  const cipher = crypto.createCipheriv(algorithm, Buffer.from(secretKey), iv);
  let encrypted = cipher.update(password);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return iv.toString("hex") + ":" + encrypted.toString("hex");
}; */

export const validateEmail = (email) => {
  // Email validation regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validatePassword = (password) => {
  // Password validation regex
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
  return passwordRegex.test(password);
};

export const validatePhoneNumber = (phoneNumber) => {
  // Phone number validation regex
  const phoneNumberRegex =
    /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/;
  return phoneNumberRegex.test(phoneNumber);
};

export const validateCurrency = (currency) => {
  const currencyRegex = /^\d+(\.\d{1,2})?$/;
  return currencyRegex.test(currency);
};
