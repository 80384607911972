import axios from "./axios.js";

const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
const service = "company";

export const companyList = (data) => {
  let url = "";

  if (Object.keys(data).length === 0) {
    url = `${apiBaseUrl}/jobseek/v1/${service}/getCompany`;
  } else {
    const queryParams = [];
    for (const [key, value] of Object.entries(data)) {
      queryParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );

      url = `${apiBaseUrl}/jobseek/v1/${service}/getCompany?${queryParams.join(
        "&"
      )}`;
    }
  }

  return axios.get(url);
};

export const companyMe = () => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/me`;
  return axios.get(url);
};

export const uploadCompanyPhoto = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/companyPhoto`;
  return axios.put(url, data);
};

export const createCompany = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/createCompanyAdminRegister`;
  return axios.post(url, { data });
};

export const editCompany = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}`;
  return axios.put(url, data);
};

export const createCompanyAgent = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/createCompanyAgent`;
  return axios.post(url, { data });
};

export const masterCreateCompany = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/masterCreateCompany`;
  return axios.post(url, { data });
};

export const masterEditCompany = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/masterEditCompany`;
  return axios.put(url, data);
};

export const masterCreateCompanyUser = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/masterCreateCompanyUser`;
  return axios.post(url, { data });
};

export const companyTransaction = (data) => {
  let url = "";

  if (Object.keys(data).length === 0) {
    url = `${apiBaseUrl}/jobseek/v1/${service}/transaction`;
  } else {
    const queryParams = [];
    for (const [key, value] of Object.entries(data)) {
      queryParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );

      url = `${apiBaseUrl}/jobseek/v1/${service}/transaction?${queryParams.join(
        "&"
      )}`;
    }
  }

  return axios.get(url);
};

export const companyTransactionTitles = () => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/transactionTitles`;
  return axios.get(url);
};

export const getTopCompanies = () => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/getCompanyListing`;
  return axios.get(url);
};

export const contactUs = (data) => {
  let url = `${apiBaseUrl}/jobseek/v1/${service}/proceed/explore`;
  const queryParams = [];

  for (const [key, value] of Object.entries(data)) {
    if (typeof value === "object") {
      value.forEach((el) => {
        queryParams.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(el)}`
        );
      });
    } else {
      queryParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    }

    url = `${apiBaseUrl}/jobseek/v1/${service}/proceed/explore?${queryParams.join(
      "&"
    )}`;
  }

  return axios.get(url);
};
