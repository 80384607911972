export default {
  jobPosts(state) {
    return state.jobPosts;
  },
  companies(state) {
    return state.companies;
  },
  companyJobPosts(state) {
    return state.companyJobPosts;
  },
  companyRecentJobPost(state) {
    return state.companyRecentJobPost;
  }
};
