import { createRouter, createWebHistory } from "vue-router";
import store from "./store/index.js";

const HomeEntryPoint = () => import("./pages/HomeEntryPoint.vue");
const EmployerLanding = () => import("./pages/EmployerLanding.vue");
const UserRegister = () => import("./pages/UserRegister.vue");
const ProfileEntryPoint = () => import("./pages/ProfileEntryPoint.vue");
const CareerAdvice = () => import("./pages/CareerAdvice.vue");
const CareerAdviceDetail = () => import("./pages/CareerAdviceDetail.vue");
const JobListEntryPoint = () => import("./pages/JobListEntryPoint.vue");
const JobDetailEntryPoint = () => import("./pages/JobDetailEntryPoint.vue");
const JobAdd = () => import("./pages/JobAdd.vue");
const JobApplicationList = () => import("./pages/JobApplicationList.vue");
const ManageApplicants = () => import("./pages/ManageApplicants.vue");
const CompanyEntryPoint = () => import("./pages/CompanyEntryPoint.vue");
const CompanyDetailEntryPoint = () =>
  import("./pages/CompanyDetailEntryPoint.vue");
const CompanyAdd = () => import("./pages/CompanyAdd.vue");
const ResumeListEntryPoint = () => import("./pages/ResumeListEntryPoint.vue");
const ResumeDetailEntryPoint = () =>
  import("./pages/ResumeDetailEntryPoint.vue");
const UserList = () => import("./pages/UserList.vue");
const UserAdd = () => import("./pages/UserAdd.vue");
const UserWithdrawalsEntryPoint = () =>
  import("./pages/UserWithdrawalsEntryPoint.vue");
const EmailVerification = () => import("./pages/EmailVerification.vue");
const CompanyTransactionList = () =>
  import("./pages/CompanyTransactionList.vue");
const PaymentStatus = () => import("./pages/PaymentStatus.vue");
const PaymentCancelled = () => import("./pages/PaymentCancelled.vue");
const AboutUs = () => import("./pages/AboutUs.vue");
const ContactUs = () => import("./pages/ContactUs.vue");
const TermsConditions = () => import("./pages/TermsConditions.vue");
const PrivacyPolicy = () => import("./pages/PrivacyPolicy.vue");
const RefundPolicy = () => import("./pages/RefundPolicy.vue");
const FrequentlyAskedQuestions = () =>
  import("./pages/FrequentlyAskedQuestions.vue");
const ProductPrices = () => import("./pages/ProductPrices.vue");
const EmployerBranding = () => import("./pages/EmployerBranding.vue");
const NotFound = () => import("./pages/NotFound.vue");

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: HomeEntryPoint },
    { path: "/employer", component: EmployerLanding },
    { path: "/register", component: UserRegister },
    {
      path: "/career-advice",
      component: CareerAdvice,
    },
    {
      path: "/career-advice/:id",
      component: CareerAdviceDetail,
      props: true
    },
    {
      path: "/profile",
      component: ProfileEntryPoint,
    },
    { path: "/jobs", component: JobListEntryPoint },
    { path: "/job/:jobId", component: JobDetailEntryPoint, props: true },
    {
      path: "/job-add",
      component: JobAdd,
      meta: { requiresAuth: true },
    },
    {
      path: "/job-applications",
      component: JobApplicationList,
      meta: { requiresAuth: true },
    },
    {
      path: "/manage-applicants/:jobId",
      component: ManageApplicants,
      props: true,
      meta: { requiresAuth: true },
    },
    { path: "/companies", component: CompanyEntryPoint },
    {
      path: "/company/me",
      components: CompanyDetailEntryPoint,
      meta: { requiresAuth: true },
    },
    {
      path: "/company/:companyId",
      component: CompanyDetailEntryPoint,
      props: true,
    },
    {
      path: "/company-add",
      component: CompanyAdd,
      meta: { requiresAuth: true },
    },
    {
      path: "/resumes",
      component: ResumeListEntryPoint,
      meta: { requiresAuth: true },
    },
    {
      path: "/resume/:resumeId",
      component: ResumeDetailEntryPoint,
      props: true,
      meta: { requiresAuth: true },
    },
    {
      path: "/users",
      component: UserList,
      meta: { requiresAuth: true },
    },
    {
      path: "/user-add",
      component: UserAdd,
      meta: { requiresAuth: true },
    },
    {
      path: "/withdrawals",
      component: UserWithdrawalsEntryPoint,
      meta: { requiresAuth: true },
    },
    {
      path: "/verify-email",
      component: EmailVerification,
      meta: { requiresAuth: true },
    },
    {
      path: "/transactions",
      component: CompanyTransactionList,
      meta: { requiresAuth: true },
    },
    {
      path: "/payment/cancelled/:orderId",
      component: PaymentCancelled,
      props: true,
      meta: { requiresAuth: true },
    },
    {
      path: "/payment/status/:orderId",
      component: PaymentStatus,
      props: true,
      meta: { requiresAuth: true },
    },
    {
      path: "/about-us",
      component: AboutUs,
    },
    {
      path: "/contact-us",
      component: ContactUs,
    },
    {
      path: "/tnc",
      component: TermsConditions,
    },
    {
      path: "/privacy-policy",
      component: PrivacyPolicy,
    },
    {
      path: "/refund-policy",
      component: RefundPolicy,
    },
    {
      path: "/faq",
      component: FrequentlyAskedQuestions,
    },
    {
      path: "/product-prices",
      component: ProductPrices,
    },
    {
      path: "/employer-branding",
      component: EmployerBranding,
    },
    { path: "/:notFound(.*)", component: NotFound },
  ],
  scrollBehavior() {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  },
});

const masterRules = (to, next) => {
  if (to.meta.requiresAuth && !store.getters["auth/userIsAuthenticated"])
    next("/");
  else {
    if (store.getters["auth/userIsAuthenticated"]) {
      if (!store.getters["auth/userIsVerified"]) {
        if (to.path !== "/verify-email") next("/verify-email");
        else next();
      } else {
        if (to.path === "/verify-email" || to.path.includes("/payment"))
          next("/");
        else if (to.path === "/company/me") next("/companies");
        else if (to.path === "/job-applications") next("/jobs");
        else next();
      }
    } else next();
  }
};

const companyAdminRules = (to, next) => {
  if (to.meta.requiresAuth && !store.getters["auth/userIsAuthenticated"])
    next("/");
  else {
    if (store.getters["auth/userIsAuthenticated"]) {
      if (!store.getters["auth/userIsVerified"]) {
        if (to.path !== "/verify-email") next("/verify-email");
        else next();
      } else {
        if (to.path === "/verify-email" || to.path === "/withdrawals")
          next("/");
        else if (to.path === "/job-applications") next("/jobs");
        else if (
          to.path === "/companies" ||
          (to.path.includes("/company") && !to.path.includes("/me"))
        )
          next("/company/me");
        else next();
      }
    } else next();
  }
};

const companyAgentRules = (to, next) => {
  if (to.meta.requiresAuth && !store.getters["auth/userIsAuthenticated"])
    next("/");
  else {
    if (store.getters["auth/userIsAuthenticated"]) {
      if (!store.getters["auth/userIsVerified"]) {
        if (to.path !== "/verify-email") next("/verify-email");
        else next();
      } else {
        if (to.path === "/verify-email" || to.path.includes("/payment"))
          next("/");
        else if (to.path === "/job-applications") next("/jobs");
        else if (
          to.path === "/companies" ||
          to.path === "/withdrawals" ||
          to.path.includes("/company") ||
          to.path.includes("/user")
        )
          next("/");
        else next();
      }
    } else next();
  }
};

const userRules = (to, next) => {
  if (to.meta.requiresAuth && !store.getters["auth/userIsAuthenticated"])
    next("/");
  else {
    if (store.getters["auth/userIsAuthenticated"]) {
      if (!store.getters["auth/userIsVerified"]) {
        if (to.path !== "/verify-email") next("/verify-email");
        else next();
      } else {
        if (to.path === "/verify-email" || to.path.includes("/payment"))
          next("/");
        else if (to.path === "/company/me") next("/companies");
        else if (
          to.path.includes("/resume") ||
          to.path.includes("/manage-applicants") ||
          to.path.includes("/user") ||
          to.path.includes("/transactions")
        )
          next("/");
        else next();
      }
    } else next();
  }
};

router.beforeEach((to, _, next) => {
  const role = localStorage.getItem("role") ?? "user";

  switch (role) {
    case "master":
      masterRules(to, next);
      break;
    case "companyAdmin":
      companyAdminRules(to, next);
      break;
    case "companyAgent":
      companyAgentRules(to, next);
      break;
    default:
      userRules(to, next);
      break;
  }
});

export default router;
