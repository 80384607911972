import axios from "./axios.js";

const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
const service = "users";

export const list = (data) => {
  let url = "";
  if (Object.keys(data).length === 0) {
    url = `${apiBaseUrl}/jobseek/v1/${service}`;
  } else {
    const queryParams = [];
    for (const [key, value] of Object.entries(data)) {
      queryParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );

      url = `${apiBaseUrl}/jobseek/v1/${service}?${queryParams.join("&")}`;
    }
  }

  return axios.get(url);
};

export const createUser = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/createUser`;
  return axios.post(url, { data });
};

export const me = () => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/me`;
  return axios.get(url);
};

export const updateUser = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}`;
  return axios.put(url, { data });
};

export const updateUserStatus = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/userEnableDisable`;
  return axios.put(url, data);
};

export const updateUserProfilePic = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/uploadProfilePic`;
  return axios.put(url, { data });
};

export const addSkill = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/skills`;
  return axios.post(url, { data });
};

export const deleteSkill = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/skills`;
  return axios.delete(url, { data: { data } });
};

export const addLanguage = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/language`;
  return axios.post(url, { data });
};

export const deleteLanguage = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/language`;
  return axios.delete(url, { data: { data } });
};

export const withdrawalRequestList = (data) => {
  let url = "";
  if (Object.keys(data).length === 0) {
    url = `${apiBaseUrl}/jobseek/v1/${service}/searchWithdrawUser`;
  } else {
    const queryParams = [];
    for (const [key, value] of Object.entries(data)) {
      queryParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );

      url = `${apiBaseUrl}/jobseek/v1/${service}/searchWithdrawUser?${queryParams.join(
        "&"
      )}`;
    }
  }

  return axios.get(url);
};

export const withdrawalRequest = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/withdrawRequest`;
  return axios.post(url, { data });
};

export const updateWithdrawalRequest = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/withdrawRequest`;
  return axios.put(url, { data });
};
