<template>
  <router-view v-if="!showHeader && !showFooter"></router-view>
  <div
    :class="{
      'mm-opened': isSidebarVisible,
      'mm-blocking': isSidebarVisible,
      'mm-background': isSidebarVisible,
      'mm-opening': isSidebarOpening,
    }"
    :style="
      isSidebarVisible
        ? { overflow: 'hidden', background: 'inherit' }
        : { background: 'inherit' }
    "
    v-else
  >
    <TheSidebar :class="{ 'mm-opened': isSidebarVisible }" />
    <TheWrapper :class="{ 'mm-slideout-opened': isSidebarVisible }">
      <template #header>
        <TheHeader
          @toggle-sidebar="toggleSidebar"
          @toggleAuthModal="toggleAuthenticationModal"
        />
      </template>
      <template #default>
        <router-view
          @toggleAuthModal="toggleAuthenticationModal"
          @openConfirmationModal="openConfirmationModal"
          @closeConfirmationModal="closeConfirmationModal"
        ></router-view>
      </template>
      <template v-if="showFooter" #footer>
        <TheFooter />
      </template>
    </TheWrapper>
    <TheBlocker
      :class="{
        'mm-blocking': isSidebarVisible,
        'mm-slideout-opened': isSidebarVisible,
      }"
      @click="toggleSidebar"
    />
    <TheBackToTop />
    <AuthenticationModal
      v-if="!isAuth"
      :visible="isAuthenticationModalVisible"
      @toggle-modal="toggleAuthenticationModal"
    />
    <ConfirmationModal
      :visible="isConfirmationModalVisible"
      :message="confirmationModalMessage"
      :event="confirmationModalEvent"
      @toggle-modal="closeConfirmationModal"
    />
  </div>
</template>

<script>
import TheWrapper from "./components/layout/TheWrapper.vue";
import TheHeader from "./components/layout/TheHeader.vue";
import TheSidebar from "./components/layout/TheSidebar.vue";
import TheBlocker from "./components/layout/TheBlocker.vue";
import TheBackToTop from "./components/layout/TheBackToTop.vue";
import TheFooter from "./components/layout/TheFooter.vue";

import AuthenticationModal from "./components/modals/AuthenticationModal.vue";
import ConfirmationModal from "./components/modals/ConfirmationModal.vue";

export default {
  components: {
    TheWrapper,
    TheHeader,
    TheSidebar,
    TheBlocker,
    TheBackToTop,
    TheFooter,

    AuthenticationModal,
    ConfirmationModal,
  },
  data() {
    return {
      screenWidth: window.innerWidth,
      isSidebarVisible: false,
      isSidebarOpening: false,
      isAuthenticationModalVisible: false,
      isConfirmationModalVisible: false,

      confirmationModalMessage: "",
      confirmationModalEvent: null,
    };
  },
  methods: {
    toggleSidebar() {
      if (this.isSidebarVisible === true) {
        this.isSidebarOpening = !this.isSidebarOpening;
        setTimeout(() => (this.isSidebarVisible = !this.isSidebarVisible), 400);
      } else {
        this.isSidebarOpening = !this.isSidebarOpening;
        this.isSidebarVisible = !this.isSidebarVisible;
      }
    },
    toggleAuthenticationModal() {
      this.isAuthenticationModalVisible = !this.isAuthenticationModalVisible;
    },
    toggleConfirmationModal() {
      this.isConfirmationModalVisible = !this.isConfirmationModalVisible;
    },
    openConfirmationModal(message, event) {
      this.confirmationModalMessage = message;
      this.confirmationModalEvent = event;
      this.isConfirmationModalVisible = true;
    },
    closeConfirmationModal() {
      this.resetConfirmationModal();
      this.isConfirmationModalVisible = false;
    },
    handleScreenResize() {
      if (this.screenWidth !== window.innerWidth) {
        this.screenWidth = window.innerWidth;
        this.isSidebarOpening = false;
        this.isSidebarVisible = false;
      }
    },

    // reset
    resetConfirmationModal() {
      this.confirmationModalMessage = "";
      this.confirmationModalEvent = null;
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleScreenResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleScreenResize);
  },
  computed: {
    showHeader() {
      const currentPath = this.$router.currentRoute.value.path;
      const ignoredPaths = ["/verify-email", "/company-verify"];
      return !ignoredPaths.includes(currentPath);
    },
    showFooter() {
      const role = localStorage.getItem("role");
      const currentPath = this.$router.currentRoute.value.path;
      const ignoredPaths = [
        "/profile",
        "/jobs",
        "/job-applications",
        "/verify-email",
        "/company-verify",
        "/withdrawals",
      ];
      const ignoredMasterPaths = [
        "/",
        "/jobs",
        "/companies",
        "/resumes",
        "/users",
        "/withdrawals",
        "/profile",
        "/transactions",
      ];
      const ignoredCompanyAdminPaths = [
        "/",
        "/jobs",
        "/resumes",
        "/users",
        "/profile",
        "/transactions",
      ];

      switch (role) {
        case "master":
          return (
            !ignoredMasterPaths.includes(currentPath) &&
            !currentPath.includes("/company") &&
            !currentPath.includes("/job") &&
            !currentPath.includes("/manage-applicants") &&
            !currentPath.includes("/resume") &&
            !currentPath.includes("/user")
          );
        case "companyAdmin":
        case "companyAgent":
          return (
            !ignoredCompanyAdminPaths.includes(currentPath) &&
            !currentPath.includes("/company") &&
            !currentPath.includes("/job") &&
            !currentPath.includes("/manage-applicants") &&
            !currentPath.includes("/resume") &&
            !currentPath.includes("/user") &&
            !currentPath.includes("/payment")
          );
        default:
          if (!this.isAuth && currentPath.includes("/profile")) return true;
          else return !ignoredPaths.includes(currentPath);
      }
    },
    isAuth() {
      return this.$store.getters["auth/userIsAuthenticated"];
    },
  },
};
</script>

<style>
@import "css/style.css";
@import "css/colors/blue.css";

a {
  cursor: pointer;
}
</style>