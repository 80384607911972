import axios from "./axios.js";

const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
const service = "payment";

export const manualTopup = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/manualTopup`;
  return axios.post(url, { data });
};

export const redirectPaymentGateway = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/redirectpaymenturl`;
  return axios.post(url, { data });
};

export const checkPaymentStatus = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/checkPaymentStatus`;
  return axios.post(url, { data });
};
