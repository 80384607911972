import axios from "./axios.js";

const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
const service = "resume";

export const upload = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}`;
  return axios.post(url, { data });
};

export const download = () => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/downloadResume`;
  return axios.post(url, { data: { id: "0" } });
};

export const getUsersResume = (data) => {
  let url = "";
  if (Object.keys(data).length === 0) {
    url = `${apiBaseUrl}/jobseek/v1/${service}/usersResume`;
  } else {
    const queryParams = [];
    for (const [key, value] of Object.entries(data)) {
      queryParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );

      url = `${apiBaseUrl}/jobseek/v1/${service}/usersResume?${queryParams.join(
        "&"
      )}`;
    }
  }

  return axios.get(url);
};

export const purchaseUserResume = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/purchaseUserResume`;
  return axios.post(url, { data });
};

export const getPurchasedResume = (data) => {
  let url = "";
  if (Object.keys(data).length === 0) {
    url = `${apiBaseUrl}/jobseek/v1/${service}/purchaseUserList`;
  } else {
    const queryParams = [];
    for (const [key, value] of Object.entries(data)) {
      queryParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );

      url = `${apiBaseUrl}/jobseek/v1/${service}/purchaseUserList?${queryParams.join(
        "&"
      )}`;
    }
  }

  return axios.get(url);
};

export const masterViewResume = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/masterViewResume`;
  return axios.post(url, { data });
};

export const companyViewResume = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/downloadUserResume`;
  return axios.post(url, { data });
}