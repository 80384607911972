export default {
  userIsAuthenticated(state) {
    const token = localStorage.getItem("token");

    if (token && !state.isLoggedIn) {
      state.isLoggedIn = !state.isLoggedIn;
    }

    return state.isLoggedIn;
  },
  userIsVerified(state) {
    const verified = localStorage.getItem("verified");

    if (verified && verified != state.isVerified) {
      state.isVerified = verified;
    }

    return state.isVerified == 1;
  },
  CompanyIsVerified(state) {
    const companyVerified = JSON.parse(localStorage.getItem("companyVerified"));

    if (companyVerified === true) {
      state.companyIsVerified = true;
    } else if (companyVerified === false) state.companyIsVerified = false;
    else state.companyIsVerified = true;

    return state.companyIsVerified;
  },
};
