import { useToast } from "vue-toastification";

import api from "../../../api/index.js";

const toast = useToast();

export default {
  async list(context, payload) {
    const { userService } = api;
    const response = await userService.list(payload);

    if (response.status === 200) {
      const { data } = response.data;
      context.commit("setUsers", data);
    } else {
      const { data } = response;
      const { errorMessage } = data;
      console.log(errorMessage);
    }
  },
  async register(context, payload) {
    const { userService } = api;
    const response = await userService.createUser(payload);

    if (response.status === 200) {
      toast.success("Registration successful");
      await this.dispatch("auth/login", {
        email: payload.email,
        password: payload.password,
      });
    } else if (response.status === 409) {
      toast.error("The email address is already registered in the system");
    } else {
      toast.error("Something went wrong, please try again");
    }
  },
  async me(context) {
    const { userService } = api;
    const response = await userService.me();

    if (response.status === 200) {
      const { data } = response.data;
      context.commit("setUser", data);
    } else {
      const { data } = response;
      const { errorMessage } = data;
      console.log(errorMessage);
    }
  },
  async updateUser(context, payload) {
    const { userService } = api;
    const response = await userService.updateUser(payload);

    if (response.status === 200) {
      toast.success("Account has been successfully updated");
      return true;
    }

    return false;
  },
  async updateUserStatus(context, payload) {
    const { userService } = api;
    const response = await userService.updateUserStatus(payload);

    if (response.status === 200) {
      toast.success("User status has been successfully updated");
      return true;
    } else {
      toast.error("Failed to update user status");
      return false;
    }
  },
  async updateUserProfilePic(context, payload) {
    const { userService } = api;
    const response = await userService.updateUserProfilePic(payload);

    if (response.status === 200) {
      toast.success("Update user profile picture success");
    } else {
      const { errorMessage } = response.data;
      toast.error(errorMessage);
    }
  },
  async addSkill(context, payload) {
    const { userService } = api;
    const response = await userService.addSkill(payload);

    return response.status === 200;
  },
  async deleteSkill(context, payload) {
    const { userService } = api;
    const response = await userService.deleteSkill(payload);

    return response.status === 200;
  },
  async addLanguage(context, payload) {
    const { userService } = api;
    const response = await userService.addLanguage(payload);

    if (response.status === 200) return true;
    else {
      const { errorMessage } = response.data;

      if (errorMessage === "Data was found in an existing record")
        toast.error("Language is already added");
      else toast.error("Failed to add language");

      return false;
    }
  },
  async deleteLanguage(context, payload) {
    const { userService } = api;
    const response = await userService.deleteLanguage(payload);

    const success = response.status === 200;

    if (!success) toast.error("Failed to add language");

    return success;
  },
  async withdrawalRequestList(context, payload) {
    const { userService } = api;
    const response = await userService.withdrawalRequestList(payload);

    if (response.status === 200) {
      const { data } = response.data;
      context.commit("setWithdrawalRequests", data);
    } else {
      const { data } = response;
      const { errorMessage } = data;
      console.log(errorMessage);
    }
  },
  async withdrawalRequest(context, payload) {
    const { userService } = api;
    const response = await userService.withdrawalRequest(payload);

    if (response.status === 200) {
      toast.success("Withdrawal request successful");
      return true;
    } else {
      toast.error("Failed to request for withdrawal");
      return false;
    }
  },
  async updateWithdrawalRequest(context, payload) {
    const { userService } = api;
    const response = await userService.updateWithdrawalRequest(payload);

    if (response.status === 200) {
      toast.success("Withdrawal request has been successfully updated");
      return true;
    } else {
      toast.error("Failed to update withdrawal request");
      return false;
    }
  },
};
