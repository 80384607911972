import axios from "./axios.js";

const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
const service = "auth";

export const login = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/login`;
  return axios.post(url, { data });
};

export const verifyEmail = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/verifyEmail`;
  return axios.post(url, { data });
};

export const forgotPassword = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/forgotPassword`;
  return axios.post(url, { data });
};

export const changePassWithVerifyCode = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/changePassWithVerifyCode`;
  return axios.post(url, { data });
};

export const changePassword = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/changePass`;
  return axios.post(url, { data });
}