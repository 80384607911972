<template>
  <teleport to="body">
    <div v-show="visible">
      <div
        class="mfp-bg my-mfp-zoom-in"
        :class="{ 'mfp-ready': popupReady }"
        @click="toggleModal"
      ></div>
      <div
        class="mfp-wrap mfp-close-btn-in mfp-auto-cursor my-mfp-zoom-in"
        :class="{
          'mfp-ready': popupReady,
          'mfp-removing': popupRemoving,
        }"
        tabindex="-1"
        style="top: 0px; pointer-events: none"
      >
        <div class="mfp-container mfp-inline-holder">
          <div class="mfp-content">
            <!-- Sign In Popup ================================================== -->
            <div id="sign-in-dialog" class="zoom-anim-dialog dialog-with-tabs">
              <TheLoadingSpinner v-if="loading === true" />
              <!--Tabs -->
              <div class="sign-in-form">
                <ul class="popup-tabs-nav">
                  <!-- <li
                    @click="changeTab('login')"
                    :class="{ active: tab === 'login' }"
                  >
                    <a>Log In</a>
                  </li>
                  <li
                    @click="changeTab('register')"
                    :class="{ active: tab === 'register' }"
                  >
                    <a>Register</a>
                  </li> -->
                </ul>

                <div class="popup-tabs-container">
                  <transition name="fade" mode="out-in">
                    <!-- Login -->
                    <div
                      class="popup-tab-content"
                      id="login"
                      v-if="tab === 'login'"
                    >
                      <!-- Welcome Text -->
                      <div class="welcome-text">
                        <h3>We're glad to see you again!</h3>
                        <span
                          >Don't have an account?
                          <a href="/register" class="register-tab"
                            >Sign Up!</a
                          ></span
                        >
                      </div>

                      <!-- Form -->
                      <div
                        class="input-with-icon-left"
                        :class="{
                          'field-invalid': loginFormErrors.email,
                        }"
                      >
                        <i class="icon-material-baseline-mail-outline"></i>
                        <input
                          type="text"
                          class="input-text with-border"
                          name="emailaddress"
                          id="emailaddress"
                          placeholder="Email Address"
                          v-model="loginEmail"
                        />
                        <div class="invalid-text">
                          {{ loginFormErrors.email }}
                        </div>
                      </div>

                      <div
                        class="input-with-icon-left"
                        :class="{
                          'field-invalid': loginFormErrors.password,
                        }"
                      >
                        <i class="icon-material-outline-lock"></i>
                        <input
                          type="password"
                          class="input-text with-border"
                          name="password"
                          id="password"
                          placeholder="Password"
                          v-model="loginPassword"
                        />
                        <div class="invalid-text">
                          {{ loginFormErrors.password }}
                        </div>
                      </div>
                      <a
                        class="forgot-password"
                        @click="changeTab('forgot_password')"
                      >
                        Forgot Password?
                      </a>

                      <!-- Button -->
                      <button
                        class="button full-width button-sliding-icon ripple-effect"
                        @click="login"
                      >
                        Log In
                        <i class="icon-material-outline-arrow-right-alt"></i>
                      </button>

                      <!-- Social Login -->
                      <!-- <div class="social-login-separator"><span>or</span></div>
                      <div class="social-login-buttons">
                        <button class="facebook-login ripple-effect">
                          <i class="icon-brand-facebook-f"></i> Log In via
                          Facebook
                        </button>
                        <button class="google-login ripple-effect">
                          <i class="icon-brand-google-plus-g"></i> Log In via
                          Google+
                        </button>
                      </div> -->
                    </div>

                    <!-- Forgot Password -->
                    <div
                      class="popup-tab-content"
                      id="login"
                      v-else-if="tab === 'forgot_password'"
                    >
                      <!-- Welcome Text -->
                      <div class="welcome-text">
                        <h3>Forgot your password? No worries!</h3>
                        <span>
                          Remembered your password?
                          <a class="register-tab" @click="changeTab('login')">
                            Log in!
                          </a>
                        </span>
                      </div>

                      <!-- Form -->
                      <div
                        class="input-with-icon-left"
                        :class="{
                          'field-invalid': forgotPasswordFormErrors.email,
                        }"
                      >
                        <i class="icon-material-baseline-mail-outline"></i>
                        <input
                          type="text"
                          class="input-text with-border"
                          name="emailaddress"
                          id="emailaddress"
                          placeholder="Email Address"
                          v-model="forgotPasswordEmail"
                        />
                        <div class="invalid-text">
                          {{ forgotPasswordFormErrors.email }}
                        </div>
                      </div>

                      <!-- Button -->
                      <button
                        class="button full-width button-sliding-icon ripple-effect"
                        @click="forgotPassword"
                      >
                        Send Verification Code
                        <i class="icon-material-outline-arrow-right-alt"></i>
                      </button>
                    </div>

                    <!-- Verify Code & Change Password -->
                    <div
                      class="popup-tab-content"
                      id="login"
                      v-else-if="tab === 'verify_code'"
                    >
                      <!-- Welcome Text -->
                      <div class="welcome-text">
                        <h3>Enter your verification code!</h3>
                        <span>
                          We've sent a code to {{ forgotPasswordEmail }}
                        </span>
                      </div>

                      <!-- Form -->
                      <div
                        class="input-with-icon-left"
                        :class="{
                          'field-invalid':
                            newPasswordFormErrors.verificationCode,
                        }"
                      >
                        <i class="icon-line-awesome-key"></i>
                        <input
                          type="text"
                          class="input-text with-border"
                          name="verification_code"
                          id="verification-code"
                          placeholder="Verification Code"
                          v-model="verificationCode"
                        />
                        <div class="invalid-text">
                          {{ newPasswordFormErrors.verificationCode }}
                        </div>
                      </div>

                      <div
                        class="input-with-icon-left"
                        :class="{
                          'field-invalid': newPasswordFormErrors.password,
                        }"
                        title="Should be at least 8 characters long"
                        data-tippy-placement="bottom"
                      >
                        <i class="icon-material-outline-lock"></i>
                        <input
                          type="password"
                          class="input-text with-border"
                          name="password-register"
                          id="password-register"
                          placeholder="New Password"
                          v-model="newPassword"
                        />
                        <div class="invalid-text">
                          {{ newPasswordFormErrors.password }}
                        </div>
                      </div>

                      <div
                        class="input-with-icon-left"
                        :class="{
                          'field-invalid': newPasswordFormErrors.passwordRepeat,
                        }"
                      >
                        <i class="icon-material-outline-lock"></i>
                        <input
                          type="password"
                          class="input-text with-border"
                          name="password-repeat-register"
                          id="password-repeat-register"
                          placeholder="Repeat New Password"
                          v-model="newPasswordRepeat"
                        />
                        <div class="invalid-text">
                          {{ newPasswordFormErrors.passwordRepeat }}
                        </div>
                      </div>

                      <!-- Button -->
                      <button
                        class="button gray button-sliding-icon ripple-effect half-width"
                        @click="cancelSetNewPassword"
                      >
                        Cancel
                        <i class="icon-material-outline-arrow-right-alt"></i>
                      </button>
                      <button
                        class="button button-sliding-icon ripple-effect half-width"
                        @click="setNewPassword"
                      >
                        Confirm
                        <i class="icon-material-outline-arrow-right-alt"></i>
                      </button>
                    </div>
                  </transition>
                </div>
              </div>
              <button
                title="Close (Esc)"
                type="button"
                class="mfp-close"
                @click.stop="toggleModal"
              ></button>
            </div>
            <!-- Sign In Popup / End -->
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import TheLoadingSpinner from "../layout/TheLoadingSpinner.vue";

import { validateEmail, validatePassword } from "../../utils/index.js";

export default {
  components: {
    TheLoadingSpinner,
  },
  props: ["visible"],
  emits: ["toggle-modal"],
  data() {
    return {
      popupReady: false,
      popupRemoving: false,
      tab: "login",
      loading: false,

      // login fields
      loginEmail: "",
      loginPassword: "",
      loginFormErrors: {},

      // forgot password fields
      forgotPasswordEmail: "",
      forgotPasswordFormErrors: {},

      // set new password fields
      verificationCode: "",
      newPassword: "",
      newPasswordRepeat: "",
      newPasswordFormErrors: {},
    };
  },
  methods: {
    toggleLoading() {
      this.loading = !this.loading;
    },
    async login() {
      // Reset form errors
      this.loginFormErrors = {};

      // Perform form validation
      if (this.loginEmail === "") {
        this.loginFormErrors["email"] = "Email is required";
      } else if (!validateEmail(this.loginEmail)) {
        this.loginFormErrors["email"] = "Email is invalid";
      }

      if (this.loginPassword === "") {
        this.loginFormErrors["password"] = "Password is required";
      }

      // Submit form if no errors
      if (Object.keys(this.loginFormErrors).length === 0) {
        this.toggleLoading();

        const success = await this.$store.dispatch("auth/login", {
          email: this.loginEmail,
          password: this.loginPassword,
        });

        this.toggleLoading();

        if (success) {
          this.toggleModal();

          /* const isVerified = this.$store.getters["auth/userIsVerified"];
          if (isVerified) this.$store.dispatch("user/me"); */
        }
      }
    },
    async forgotPassword() {
      // Reset form errors
      this.forgotPasswordFormErrors = {};

      // Perform form validation
      if (this.forgotPasswordEmail === "") {
        this.forgotPasswordFormErrors["email"] = "Email is required";
      } else if (!validateEmail(this.forgotPasswordEmail)) {
        this.forgotPasswordFormErrors["email"] = "Email is invalid";
      }

      // Submit form if no errors
      if (Object.keys(this.forgotPasswordFormErrors).length === 0) {
        const success = await this.$store.dispatch("auth/forgotPassword", {
          email: this.forgotPasswordEmail,
        });

        if (success) {
          this.changeTab("verify_code");
        }
      }
    },
    async setNewPassword() {
      // Reset form errors
      this.newPasswordFormErrors = {};

      // Perform form validation
      if (this.verificationCode === "") {
        this.newPasswordFormErrors["verificationCode"] =
          "Verification code is required";
      }

      if (this.newPassword === "") {
        this.newPasswordFormErrors["password"] = "New password is required";
      } else if (!validatePassword(this.newPassword)) {
        this.newPasswordFormErrors["password"] =
          "Your password must be at least 8 characters long, contain at least one number and have a mixture of uppercase and lowercase letters";
      }

      if (this.newPasswordRepeat === "") {
        this.newPasswordFormErrors["passwordRepeat"] =
          "Please re-enter your new password";
      } else if (this.newPasswordRepeat != this.newPassword) {
        this.newPasswordFormErrors["passwordRepeat"] =
          "New passwords do not match";
      }

      // Submit form if no errors
      if (Object.keys(this.newPasswordFormErrors).length === 0) {
        const success = await this.$store.dispatch(
          "auth/changePassWithVerifyCode",
          {
            email: this.forgotPasswordEmail,
            verificationCode: this.verificationCode,
            password: this.newPassword,
          }
        );

        if (success) this.cancelSetNewPassword();
      }
    },
    cancelSetNewPassword() {
      this.resetNewPassword();
      this.toggleModal();
      this.changeTab("login");
    },
    resetLogin() {
      this.loginEmail = "";
      this.loginPassword = "";
      this.loginFormErrors = {};
    },
    resetForgotPassword() {
      this.forgotPasswordEmail = "";
      this.forgotPasswordFormErrors = {};
    },
    resetNewPassword() {
      this.verificationCode = "";
      this.newPassword = "";
      this.newPasswordRepeat = "";
      this.newPasswordFormErrors = {};
    },
    changeTab(selectedTab) {
      switch (selectedTab) {
        case "login": {
          this.resetForgotPassword();
          break;
        }
        case "forgot_password": {
          this.resetLogin();
          break;
        }
        default:
          break;
      }

      this.tab = selectedTab;
    },
    toggleModal() {
      this.popupReady = false;
      this.popupRemoving = true;
      setTimeout(() => this.$emit("toggle-modal"), 300);
    },
  },
  watch: {
    visible(val) {
      if (val === true) {
        setTimeout(() => {
          this.popupReady = val;
          this.popupRemoving = !val;
        }, 50);
      }
    },
  },
};
</script>

<style scoped>
#sign-in-dialog {
  pointer-events: all;
}

.button.half-width {
  display: inline-block;
  max-width: 49%;
}

.button.half-width:last-child {
  margin-left: 2%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s linear;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.field-invalid i,
.field-invalid .input-text {
  border: 1px solid red !important;
}

.field-invalid .input-text {
  margin: 0;
}

.invalid-text {
  color: red;
  margin-bottom: 22px;
}
</style>