import axios from "./axios.js";

const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
const service = "workexperience";

export const createWorkExperience = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}`;
  return axios.post(url, { data });
};

export const getWorkExperience = () => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}`;
  return axios.get(url);
};

export const updateWorkExperience = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}`;
  return axios.put(url, data);
};

export const deleteWorkExperience = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}`;
  return axios.delete(url, { data: { data } });
};
