<template>
  <!-- Header Container ================================================== -->
  <header
    id="header-container"
    class="fullwidth"
    :style="headerContainerPositionIsFixed ? { position: 'fixed' } : {}"
  >
    <!-- Header -->
    <div id="header">
      <div class="container">
        <!-- Left Side Content -->
        <div class="left-side">
          <!-- Logo -->
          <div id="logo">
            <router-link to="/">
              <img src="/images/JobXphere_Logo.png" alt="logo" />
            </router-link>
          </div>

          <!-- Main Navigation -->
          <nav id="navigation">
            <ul id="responsive" v-if="isUser">
              <li>
                <router-link :class="{ current: isActive('/') }" to="/">
                  Home
                </router-link>
              </li>
              <li>
                <router-link :class="{ current: isActive('/jobs') }" to="/jobs">
                  Job Search
                </router-link>
              </li>
              <li v-if="!isAuth">
                <router-link
                  :class="{ current: isActive('/profile') }"
                  to="/profile"
                >
                  Profile
                </router-link>
              </li>
              <li>
                <router-link
                  :class="{ current: isActive('/career-advice') }"
                  to="/career-advice"
                >
                  Career Advice
                </router-link>
              </li>
              <li>
                <router-link
                  :class="{ current: isActive('/companies') }"
                  to="/companies"
                >
                  Company Profiles
                </router-link>
              </li>
            </ul>
          </nav>
          <div class="clearfix"></div>
          <!-- Main Navigation / End -->
        </div>
        <!-- Left Side Content / End -->

        <!-- Right Side Content / End -->
        <div v-if="isUser" class="right-side">
          <transition name="bounce" mode="out-in">
            <!-- User Menu -->
            <div class="header-widget" v-if="isAuth === true">
              <!-- Messages -->
              <div
                class="header-notifications user-menu"
                :class="{ active: isUserMenuVisible }"
              >
                <div
                  class="header-notifications-trigger"
                  @click="toggleUserMenu"
                >
                  <a>
                    <div class="user-avatar">
                      <img :src="profileUrl" alt="user-avatar" />
                    </div>
                  </a>
                </div>

                <!-- Dropdown -->
                <div class="header-notifications-dropdown">
                  <!-- User Status -->
                  <div class="user-status">
                    <!-- User Name / Avatar -->
                    <div class="user-details">
                      <div class="user-avatar">
                        <img :src="profileUrl" alt="user-avatar" />
                      </div>
                      <div class="user-name">
                        {{
                          user ? `${user.firstName} ${user.lastName}` : "User"
                        }}
                        <span class="credit">
                          Credit: {{ user?.credit ?? "0.00" }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <ul class="user-menu-small-nav">
                    <li>
                      <router-link to="/job-applications">
                        <i class="icon-material-outline-dashboard"></i>
                        Job Applications
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/profile">
                        <i class="icon-feather-user"></i>
                        Profile
                      </router-link>
                    </li>
                    <li v-if="isAuth">
                      <router-link to="/withdrawals">
                        <i class="icon-line-awesome-money"></i>
                        Withdrawals
                      </router-link>
                    </li>
                    <li>
                      <a @click="logout">
                        <i class="icon-material-outline-power-settings-new">
                        </i>
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>

                <!-- Dropdown Dismiss -->
                <div
                  class="user-menu-dismiss"
                  v-show="isUserMenuVisible"
                  @click="toggleUserMenu"
                ></div>
                <!-- Dropdown Dismiss / End -->
              </div>
            </div>
            <!-- User Menu / End -->

            <div class="header-widget" v-else>
              <a
                class="popup-with-zoom-anim log-in-button"
                @click="toggleAuthenticationModal"
              >
                <i class="icon-feather-log-in"></i>
                <span>Log In / Register</span>
              </a>
            </div>
          </transition>

          <!-- Mobile Navigation Button -->
          <span class="mmenu-trigger" @click="toggleSidebar" v-if="isUser">
            <button class="hamburger hamburger--collapse" type="button">
              <span class="hamburger-box">
                <span class="hamburger-inner"></span>
              </span>
            </button>
          </span>
        </div>
        <!-- Right Side Content / End -->
      </div>
    </div>
    <!-- Header / End -->
  </header>
  <div class="clearfix"></div>
  <!-- Header Container / End -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  emits: ["toggle-sidebar", "toggleAuthModal"],
  data() {
    return {
      user: null,
      role: "user",
      isUserMenuVisible: false,
      headerContainerPositionIsFixed: false,
    };
  },
  methods: {
    handleScreenResize() {
      if (window.innerWidth > 1099) {
        this.headerContainerPositionIsFixed = true;
      }
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.toggleUserMenu();
      this.toggleAuthenticationModal();
    },
    toggleSidebar() {
      this.$emit("toggle-sidebar");
    },
    toggleUserMenu() {
      if (this.isUser) this.isUserMenuVisible = !this.isUserMenuVisible;
    },
    toggleAuthenticationModal() {
      this.$emit("toggleAuthModal");
    },
    isActive(route) {
      return this.$router.currentRoute.value.path === route;
    },
  },
  created() {
    if (this.isAuth) this.$store.dispatch("user/me");
  },
  mounted() {
    if (window.innerWidth > 1099) this.headerContainerPositionIsFixed = true;
    window.addEventListener("resize", this.handleScreenResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleScreenResize);
  },
  computed: {
    isAuth() {
      return this.$store.getters["auth/userIsAuthenticated"];
    },
    profileUrl() {
      return this.user && this.user.profileUrl
        ? this.user.profileUrl
        : "/images/user-avatar-placeholder.png";
    },
    isMaster() {
      return this.role === "master";
    },
    isUser() {
      return this.role === "user";
    },
    // vuex
    ...mapGetters("user", { storedUser: "user" }),
  },
  watch: {
    storedUser(newVal) {
      const isEmptyObj =
        Object.keys(this.$store.getters["user/user"]).length === 0 &&
        this.$store.getters["user/user"].constructor === Object;

      this.user = isEmptyObj ? null : newVal;
      this.role = localStorage.getItem("role") ?? "user";
    },
  },
};
</script>

<style scoped>
.user-menu-dismiss {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}

.user-avatar img {
  width: 42px;
  height: 42px;
  object-fit: cover;
}

.user-avatar::after {
  content: none;
}

.user-name {
  width: 100%;
}

.credit {
  background-color: var(--color);
  border-radius: 5px;
  color: white;
  padding: 2px 3px 2px 3px;
  width: fit-content;
}

@media (max-width: 1099px) {
  #header .right-side {
    right: 0;
  }
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>