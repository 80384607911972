import { useToast } from "vue-toastification";

import api from "../../../api/index.js";
import router from "../../../router.js";

const toast = useToast();

export default {
  async getCompanyList(context, payload = {}) {
    const { companyService } = api;
    const response = await companyService.companyList(payload);

    if (response.status === 200) {
      const { data } = response.data;
      context.commit("setCompanies", data);
    } else {
      const { data } = response;
      const { errorMessage } = data;
      console.log(errorMessage);
    }
  },
  async getCompany(context) {
    const { companyService } = api;
    const response = await companyService.companyMe();

    if (response.status === 200) {
      const { data } = response.data;
      context.commit("setCompany", data);
    } else {
      const { data } = response;
      const { errorMessage } = data;
      console.log(errorMessage);
    }
  },
  async registerCompany(context, payload) {
    const { companyService } = api;
    const response = await companyService.createCompany(payload);

    if (response.status === 200) {
      toast.success(
        "Company account created successfully. The account requires approval from the system admin, which will take 1-3 days for processing and validation"
      );

      router.push("/");
    } else if (response.status === 409) {
      toast.error("Email has been used for register");
    } else {
      toast.error("Something went wrong, please try again");
    }
  },
  async editCompany(context, payload) {
    const { companyService } = api;
    const response = await companyService.editCompany(payload);

    if (response.status === 200) {
      toast.success("Company has been successfully updated");
      return true;
    } else {
      toast.error("Failed to update company");
      return false;
    }
  },
  async createCompanyAgent(context, payload) {
    const { companyService } = api;
    const response = await companyService.createCompanyAgent(payload);

    if (response.status === 200) {
      toast.success("Agent has been successfully added");
      return true;
    } else {
      toast.error("Failed to add agent");
      return false;
    }
  },
  async uploadCompanyPhoto(context, payload) {
    let entity = "image";
    const { data } = payload;
    if (data.types) entity = data.types;

    const { companyService } = api;
    const response = await companyService.uploadCompanyPhoto(payload);

    if (response.status === 200) {
      toast.success(`Company ${entity} has been successfully uploaded`);
      return true;
    } else {
      const { errorMessage } = response.data;
      console.log(errorMessage);
      toast.error(`Failed to upload company ${entity}`);
      return false;
    }
  },

  // master
  async masterCreateCompany(context, payload) {
    const { companyService } = api;
    const response = await companyService.masterCreateCompany(payload);

    if (response.status === 200) {
      toast.success("Company has been successfully added");
      return true;
    } else {
      toast.error("Failed to add company");
      return false;
    }
  },
  async masterEditCompany(context, payload) {
    const { companyService } = api;
    const response = await companyService.masterEditCompany(payload);

    if (response.status === 200) {
      toast.success("Company has been successfully updated");
      return true;
    } else {
      toast.error("Failed to update company");
      return false;
    }
  },
  async masterAddUser(context, payload) {
    const { companyService } = api;
    const response = await companyService.masterCreateCompanyUser(payload);

    if (response.status === 200) {
      toast.success("User has been successfully added");
      return true;
    } else {
      toast.error("Failed to add user");
      return false;
    }
  },
  async getCompanyTransactions(context, payload = {}) {
    const { companyService } = api;
    const response = await companyService.companyTransaction(payload);

    if (response.status === 200) {
      const { data } = response.data;
      context.commit("setTransactions", data);
    } else {
      const { data } = response;
      const { errorMessage } = data;
      console.log(errorMessage);
    }
  },
  async getTransactionTitles(context) {
    const { companyService } = api;
    const response = await companyService.companyTransactionTitles();

    if (response.status === 200) {
      const { data } = response.data;
      context.commit("setTransactionTitles", data);
    } else {
      const { data } = response;
      const { errorMessage } = data;
      console.log(errorMessage);
    }
  },
  async getTopCompanies(context) {
    const { companyService } = api;
    const response = await companyService.getTopCompanies();

    if (response.status === 200) {
      const { data } = response.data;
      context.commit("setTopCompanies", data);
    } else {
      const { data } = response;
      const { errorMessage } = data;
      console.error(errorMessage);
    }
  },
  async contactUs(context, payload) {
    const { companyService } = api;
    const response = await companyService.contactUs(payload);

    if (response.status === 200)
      toast.success("Thank you! Your submission has been sent");
    else toast.error("Sorry! Something went wrong with your submission");
  },
};
