<template>
  <div class="loading-spinner-wrapper" :class="positionClass">
    <div class="loading-spinner"></div>
  </div>
</template>

<script>
export default {
  props: ["position"],
  computed: {
    positionClass() {
      return this.position === "absolute" ? "absolute" : "fixed";
    },
  },
};
</script>

<style scoped>
.loading-spinner-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 112;
  background: rgba(17, 17, 17, 0.25);
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.loading-spinner {
  width: 100px;
  height: 100px;
  border: 10px solid var(--color);
  border-radius: 50%;
  border-top-color: #0001;
  display: inline-block;
  animation: loadingSpinner 0.7s linear infinite;
}

@keyframes loadingSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>