import axios from "./axios.js";

const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
const service = "jobapplication";

export const get = (data) => {
  let url = "";

  if (Object.keys(data).length === 0) {
    url = `${apiBaseUrl}/jobseek/v1/${service}`;
  } else {
    const queryParams = [];
    for (const [key, value] of Object.entries(data)) {
      queryParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );

      url = `${apiBaseUrl}/jobseek/v1/${service}?${queryParams.join("&")}`;
    }
  }

  return axios.get(url);
};

export const userJobApplicationList = (data) => {
  let url = "";

  if (Object.keys(data).length === 0) {
    url = `${apiBaseUrl}/jobseek/v1/${service}/userJobApplicationList`;
  } else {
    const queryParams = [];
    for (const [key, value] of Object.entries(data)) {
      queryParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );

      url = `${apiBaseUrl}/jobseek/v1/${service}/userJobApplicationList?${queryParams.join(
        "&"
      )}`;
    }
  }

  return axios.get(url);
};

export const userApplyJob = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}`;
  return axios.post(url, { data });
};

export const updateJobApplication = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}`;
  return axios.put(url, data);
};

export const jobReferral = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/jobapplicationbehalf`;
  return axios.post(url, { data });
};
