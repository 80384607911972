import * as authService from "./authService.js";
import * as userService from "./userService.js";
import * as resumeService from "./resumeService.js";
import * as workExperienceService from "./workExperienceService.js";
import * as industryService from "./industryService.js";
import * as jobPostService from "./jobPostService.js";
import * as jobApplicationService from "./jobApplicationService.js";
import * as companyService from "./companyService.js";
import * as paymentService from "./paymentService.js";
import * as miscService from "./miscService.js";

export default {
  authService,
  userService,
  resumeService,
  workExperienceService,
  industryService,
  jobPostService,
  jobApplicationService,
  companyService,
  paymentService,
  miscService,
};
