<template>
  <teleport to="body">
    <div id="backtotop" :class="{ visible: isVisible }" @click="scrollToTop">
      <a href="#"></a>
    </div>
  </teleport>
</template>

<script>
export default {
  data() {
    return {
      pxShow: 600,
      scrollSpeed: 500,
      isVisible: false,
    };
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset >= this.pxShow) {
        this.isVisible = true;
      } else {
        this.isVisible = false;
      }
    },
    scrollToTop() {
      let currentPosition = window.pageYOffset;
      let step = Math.floor(-currentPosition / (this.scrollSpeed / 15));
      let scrollInterval = setInterval(() => {
        currentPosition += step;
        if (currentPosition <= 0) {
          clearInterval(scrollInterval);
        }
        window.scrollTo(0, currentPosition);
      }, 15);
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>