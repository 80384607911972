import api from "../../../api/index.js";

export default {
  async getIndustries(context) {
    const { industryService } = api;
    const response = await industryService.getIndustries();

    if (response.status === 200) {
      const { data } = response.data;
      context.commit("setIndustries", data);
    } else {
      const { data } = response;
      const { errorMessage } = data;
      console.log(errorMessage);
    }
  },
  async getSpecializations(context, payload) {
    const { industryService } = api;
    const response = await industryService.getSpecializations(payload);

    if (response.status === 200) {
      const { data } = response.data;
      context.commit("setSpecializations", data);
    } else {
      const { data } = response;
      const { errorMessage } = data;
      console.log(errorMessage);
    }

    return response.status === 200;
  },
  resetSpecializations(context) {
    context.commit("setSpecializations", []);
  },
  async getPositions(context, payload) {
    const { industryService } = api;
    const response = await industryService.getPositions(payload);

    if (response.status === 200) {
      const { data } = response.data;
      context.commit("setPositions", data);
    } else {
      const { data } = response;
      const { errorMessage } = data;
      console.log(errorMessage);
    }
  },
};
