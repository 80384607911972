import { useToast } from "vue-toastification";

import api from "../../../api/index.js";

const toast = useToast();

export default {
  async manualTopup(context, payload) {
    const { paymentService } = api;
    const response = await paymentService.manualTopup(payload);

    if (response.status === 200) {
      toast.success("Company credit has been successfully reloaded");
      return true;
    } else {
      const { errorMessage } = response.data;
      console.log(errorMessage);
      toast.error("Failed to reload company credit");
      return false;
    }
  },
  async redirectPaymentGateway(context, payload) {
    const { paymentService } = api;
    const response = await paymentService.redirectPaymentGateway(payload);

    if (response.status === 200) {
      const { data } = response.data;

      return data;
    } else {
      const { errorMessage } = response.data;
      console.log(errorMessage);
      toast.error("Failed to reload company credit");
      
      return null;
    }
  },
  async checkPaymentStatus(context, payload) {
    const { paymentService } = api;
    const response = await paymentService.checkPaymentStatus(payload);

    if (response.status === 200) {
      const { data } = response.data;
      return data;
    } else return [];
  },
};
