export default {
  setIndustries(state, payload) {
    state.industries = payload;
  },
  setSpecializations(state, payload) {
    state.specializations = payload;
  },
  setPositions(state, payload) {
    state.positions = payload;
  },
};
