import { useToast } from "vue-toastification";

import api from "../../../api/index.js";

const toast = useToast();

export default {
  async getJobPosts(context, payload = {}) {
    const { jobPostService } = api;
    const response = await jobPostService.get(payload);

    if (response.status === 200) {
      const { data } = response.data;
      context.commit("setJobPosts", data);
    } else {
      const { data } = response;
      const { errorMessage } = data;
      console.log(errorMessage);
    }
  },
  async getCompanies(context) {
    const { jobPostService } = api;
    const response = await jobPostService.getCompanyList();

    if (response.status === 200) {
      const { data } = response.data;
      context.commit("setCompanies", data);
    } else {
      const { data } = response;
      const { errorMessage } = data;
      console.log(errorMessage);
    }
  },
  async getCompanyJobPosts(context, payload = {}) {
    const { jobPostService } = api;
    const response = await jobPostService.getCompanyListJobBoards(payload);

    if (response.status === 200) {
      const { data } = response.data;
      context.commit("setCompanyJobPosts", data);
    } else {
      const { data } = response;
      const { errorMessage } = data;
      console.log(errorMessage);
    }
  },
  async createJob(context, payload) {
    const { jobPostService } = api;
    const response = await jobPostService.create(payload);

    if (response.status === 200) {
      const message =
        payload.jobStatus === "active"
          ? "Job has been successfully posted"
          : "Job has been successfully saved as draft";
      toast.success(message);
      return true;
    } else {
      const { data } = response;
      const { errorMessage } = data;
      const jobIsActive = payload.jobStatus === "active";
      const keyword = jobIsActive === true ? "post job" : "save job as draft";

      const toastMessage =
        errorMessage === "Insufficient Credit"
          ? `Failed to ${keyword} due to insufficient credit`
          : `Failed to ${keyword}`;

      toast.error(toastMessage);
      return false;
    }
  },
  async masterCreateJob(context, payload) {
    const { jobPostService } = api;
    const response = await jobPostService.masterCreate(payload);

    if (response.status === 200) {
      const message =
        payload.jobStatus === "active"
          ? "Job has been successfully posted"
          : "Job has been successfully saved as draft";
      toast.success(message);
      return true;
    } else {
      toast.error("Failed to post job");
      return false;
    }
  },
  async editJob(context, payload) {
    const { jobPostService } = api;
    const response = await jobPostService.edit(payload);

    if (response.status === 200) {
      toast.success("Job has been successfully updated");
      return true;
    } else {
      toast.error("Failed to update job");
      return false;
    }
  },
  async getCompanyRecentJobPost(context) {
    const { jobPostService } = api;
    const response = await jobPostService.getCompanyRecentJobPost();

    if (response.status === 200) {
      const { data } = response.data;
      context.commit("setCompanyRecentJobPost", data);
    } else {
      const { data } = response;
      const { errorMessage } = data;
      console.log(errorMessage);
    }
  },
};
