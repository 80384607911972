<template>
  <teleport to="body">
    <div v-show="visible">
      <div
        class="mfp-bg my-mfp-zoom-in"
        :class="{ 'mfp-ready': popupReady }"
        @click="toggleModal"
      ></div>
      <div
        class="mfp-wrap mfp-close-btn-in mfp-auto-cursor my-mfp-zoom-in"
        :class="{
          'mfp-ready': popupReady,
          'mfp-removing': popupRemoving,
        }"
        tabindex="-1"
        style="top: 0px; pointer-events: none"
      >
        <div class="mfp-container mfp-inline-holder">
          <div class="mfp-content">
            <!-- Sign In Popup ================================================== -->
            <div id="sign-in-dialog" class="zoom-anim-dialog dialog-with-tabs">
              <TheLoadingSpinner v-if="loading === true" />
              <!--Tabs -->
              <div class="sign-in-form">
                <ul class="popup-tabs-nav"></ul>

                <div class="popup-tabs-container">
                  <!-- Confirmation -->
                  <div class="popup-tab-content">
                    <!-- Message -->
                    <div class="welcome-text">
                      <h3>Are you sure you want to {{ message }} ?</h3>
                    </div>

                    <!-- Button -->
                    <button
                      class="button gray button-sliding-icon ripple-effect half-width"
                      @click="toggleModal"
                    >
                      Cancel
                      <i class="icon-material-outline-arrow-right-alt"></i>
                    </button>
                    <button
                      class="button button-sliding-icon ripple-effect half-width"
                      @click="confirmEvent"
                    >
                      Confirm
                      <i class="icon-material-outline-arrow-right-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
              <button
                title="Close (Esc)"
                type="button"
                class="mfp-close"
                @click.stop="toggleModal"
              ></button>
            </div>
            <!-- Sign In Popup / End -->
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import TheLoadingSpinner from "../layout/TheLoadingSpinner.vue";

export default {
  components: {
    TheLoadingSpinner,
  },
  props: ["visible", "message", "event"],
  emits: ["toggle-modal"],
  data() {
    return {
      popupReady: false,
      popupRemoving: false,
      loading: false,
    };
  },
  methods: {
    toggleLoading() {
      this.loading = !this.loading;
    },
    async confirmEvent() {
      if (this.event) this.event();
    },
    toggleModal() {
      this.popupReady = false;
      this.popupRemoving = true;
      setTimeout(() => this.$emit("toggle-modal"), 300);
    },
  },
  watch: {
    visible(val) {
      if (val === true) {
        setTimeout(() => {
          this.popupReady = val;
          this.popupRemoving = !val;
        }, 50);
      }
    },
  },
};
</script>

<style scoped>
#sign-in-dialog {
  pointer-events: all;
}

.dialog-with-tabs#sign-in-dialog {
  max-width: 60%;
}

.button.half-width {
  display: inline-block;
  max-width: 49%;
}

.button.half-width:last-child {
  margin-left: 2%;
}
</style>