export default {
  setUser(state, payload) {
    state.user = payload;
  },
  setUsers(state, payload) {
    state.users = payload;
  },
  setWithdrawalRequests(state, payload) {
    state.withdrawalRequests = payload;
  },
};
