import { useToast } from "vue-toastification";

import api from "../../../api/index.js";

const toast = useToast();

export default {
  async uploadResume(context, payload) {
    const { resumeService } = api;
    const response = await resumeService.upload(payload);

    if (response.status === 200) {
      toast.success("Upload resume success");
    } else {
      const { errorMessage } = response.data;
      toast.error(errorMessage);
    }
  },
  async downloadResume() {
    const { resumeService } = api;
    const response = await resumeService.download();

    if (response.status === 200) {
      const { data } = response.data;
      await fetch(data)
        .then(async (res) => {
          if (res.ok) {
            // Create a blob from the fetched data
            const blob = await res.blob();

            // Create a temporary URL for the blob
            const url = URL.createObjectURL(blob);

            // Create an anchor element to trigger the download
            const link = document.createElement("a");
            link.href = url;
            link.download = "resume.pdf"; // You can change the file name as needed
            link.click();

            // Clean up by revoking the object URL
            URL.revokeObjectURL(url);
          } else throw Error();
        })
        .catch(() => {
          toast.error(
            "An issue occurred while attempting to download the resume"
          );
        });
    }
  },
  async masterViewResume(context, payload) {
    const { resumeService } = api;
    const response = await resumeService.masterViewResume(payload);

    if (response.status === 200) {
      const { data } = response.data;
      await fetch(data)
        .then(async (res) => {
          if (res.ok) {
            // Create a blob from the fetched data
            const blob = await res.blob();

            // Create a temporary URL for the blob
            const url = URL.createObjectURL(blob);

            // Open the PDF in a new browser tab or window
            window.open(url, "_blank");

            // Clean up by revoking the object URL
            URL.revokeObjectURL(url);
          } else throw Error();
        })
        .catch(() => {
          toast.error("An issue occurred while attempting to view the resume");
        });
    }
  },
  async companyViewResume(context, payload) {
    const { resumeService } = api;
    const response = await resumeService.companyViewResume(payload);

    if (response.status === 200) {
      const { data } = response.data;
      await fetch(data)
        .then(async (res) => {
          if (res.ok) {
            // Create a blob from the fetched data
            const blob = await res.blob();

            // Create a temporary URL for the blob
            const url = URL.createObjectURL(blob);

            // Open the PDF in a new browser tab or window
            window.open(url, "_blank");

            // Clean up by revoking the object URL
            URL.revokeObjectURL(url);
          } else throw Error();
        })
        .catch(() => {
          toast.error("An issue occurred while attempting to view the resume");
        });
    }
  },
  async getUsersResume(context, payload = {}) {
    const { resumeService } = api;
    const response = await resumeService.getUsersResume(payload);

    if (response.status === 200) {
      const { data } = response.data;
      context.commit("setResumes", data);
    }
  },
  async purchaseUserResume(context, payload) {
    const { resumeService } = api;
    const response = await resumeService.purchaseUserResume(payload);

    if (response.status === 200) {
      toast.success("Resume has been successfully purchased");
      return true;
    } else {
      toast.error("Failed to purchase resume");
      return false;
    }
  },
  async getPurchasedResume(context, payload = {}) {
    const { resumeService } = api;
    const response = await resumeService.getPurchasedResume(payload);

    if (response.status === 200) {
      const { data } = response.data;
      context.commit("setResumes", data);
    }
  },
};
