<template>
  <!-- Footer ================================================== -->
  <div id="footer">
    <!-- Footer Top Section -->
    <div class="footer-top-section">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <!-- Footer Rows Container -->
            <div class="footer-rows-container">
              <!-- Left Side -->
              <div class="footer-rows-left">
                <div class="footer-row">
                  <div class="footer-row-inner footer-logo">
                    <img src="/images/JobXphere_Logo_White.png" alt="logo" />
                  </div>
                </div>
              </div>

              <!-- Right Side -->
              <div class="footer-rows-right">
                <!-- Social Icons -->
                <div class="footer-row">
                  <div class="footer-row-inner">
                    <ul class="footer-social-links">
                      <li>
                        <a
                          href="#"
                          title="Facebook"
                          data-tippy-placement="bottom"
                          data-tippy-theme="light"
                        >
                          <i class="icon-brand-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          title="Twitter"
                          data-tippy-placement="bottom"
                          data-tippy-theme="light"
                        >
                          <i class="icon-brand-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          title="Google Plus"
                          data-tippy-placement="bottom"
                          data-tippy-theme="light"
                        >
                          <i class="icon-brand-google-plus-g"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          title="LinkedIn"
                          data-tippy-placement="bottom"
                          data-tippy-theme="light"
                        >
                          <i class="icon-brand-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                    <div class="clearfix"></div>
                  </div>
                </div>

                <!-- Language Switcher -->
                <!-- <div class="footer-row">
                  <div class="footer-row-inner">
                    <select
                      class="selectpicker language-switcher"
                      data-selected-text-format="count"
                      data-size="5"
                    >
                      <option selected>English</option>
                      <option>Français</option>
                      <option>Español</option>
                      <option>Deutsch</option>
                    </select>
                  </div>
                </div> -->
              </div>
            </div>
            <!-- Footer Rows Container / End -->
          </div>
        </div>
      </div>
    </div>
    <!-- Footer Top Section / End -->

    <!-- Footer Middle Section -->
    <div class="footer-middle-section">
      <div class="container">
        <div class="row">
          <!-- Links -->
          <div class="col-xl-4">
            <div class="footer-links">
              <h3>For Jobseeker</h3>
              <ul>
                <li>
                  <router-link to="/">
                    <span>Job Search</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/profile">
                    <span>Profile</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/career-advice">
                    <span>Career Advice</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <!-- Links -->
          <div class="col-xl-4">
            <div class="footer-links">
              <h3>For Employer</h3>
              <ul>
                <li>
                  <router-link to="/employer">
                    <span>Home</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/register">
                    <span>Register</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/product-prices">
                    <span>Product &amp; Prices</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/contact-us">
                    <span>Headhunting Services</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/employer-branding">
                    <span>Employer Branding</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/contact-us">
                    <span>Hiring Advice</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <!-- Links -->
          <div class="col-xl-4">
            <div class="footer-links">
              <h3>JobXphere</h3>
              <ul>
                <li>
                  <router-link to="/about-us">
                    <span>About Us</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/contact-us">
                    <span>Contact Us</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/faq">
                    <span>FAQ</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/privacy-policy">
                    <span>Privacy Policy</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/refund-policy">
                    <span>Refund Policy</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/tnc">
                    <span>Terms &amp; Conditions</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer Middle Section / End -->

    <!-- Footer Copyrights -->
    <div class="footer-bottom-section">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            © {{ year }} <strong>JobXphere</strong>. All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
    <!-- Footer Copyrights / End -->
  </div>
  <!-- Footer / End -->
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>