export default {
  companies(state) {
    return state.companies;
  },
  company(state) {
    return state.company;
  },
  user(state) {
    return state.user;
  },
  transactions(state) {
    return state.transactions;
  },
  transactionTitles(state) {
    return state.transactionTitles;
  },
  topCompanies(state) {
    return state.topCompanies;
  }
};
