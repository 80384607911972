import axios from "./axios.js";

const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
const service = "jobpost";

export const create = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}`;
  return axios.post(url, { data });
};

export const masterCreate = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/masterCreateJobPost`;
  return axios.post(url, { data });
}

export const edit = (data) => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}`;
  return axios.put(url, data);
};

export const get = (data) => {
  let url = "";

  if (Object.keys(data).length === 0) {
    url = `${apiBaseUrl}/jobseek/v1/${service}/getJobPostList`;
  } else {
    const queryParams = [];
    for (const [key, value] of Object.entries(data)) {
      queryParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );

      url = `${apiBaseUrl}/jobseek/v1/${service}/getJobPostList?${queryParams.join(
        "&"
      )}`;
    }
  }

  return axios.get(url);
};

export const getCompanyList = () => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/companyList`;
  return axios.get(url);
};

export const getCompanyListJobBoards = (data) => {
  let url = "";

  if (Object.keys(data).length === 0) {
    url = `${apiBaseUrl}/jobseek/v1/${service}/companyListJobBoards`;
  } else {
    const queryParams = [];
    for (const [key, value] of Object.entries(data)) {
      queryParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );

      url = `${apiBaseUrl}/jobseek/v1/${service}/companyListJobBoards?${queryParams.join(
        "&"
      )}`;
    }
  }

  return axios.get(url);
};

export const getCompanyRecentJobPost = () => {
  const url = `${apiBaseUrl}/jobseek/v1/${service}/companyRecentJobPost`;
  return axios.get(url);
}