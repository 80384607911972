export default {
  setCompanies(state, payload) {
    state.companies = payload;
  },
  setCompany(state, payload) {
    state.company = payload;
  },
  setUser(state, payload) {
    state.user = payload;
  },
  setTransactions(state, payload) {
    state.transactions = payload;
  },
  setTransactionTitles(state, payload) {
    state.transactionTitles = payload;
  },
  setTopCompanies(state, payload) {
    state.topCompanies = payload;
  }
};