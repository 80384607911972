export default {
  setJobPosts(state, payload) {
    state.jobPosts = payload;
  },
  setCompanies(state, payload) {
    state.companies = payload;
  },
  setCompanyJobPosts(state, payload) {
    state.companyJobPosts = payload;
  },
  setCompanyRecentJobPost(state, payload) {
    state.companyRecentJobPost = payload;
  }
};
