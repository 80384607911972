import { useToast } from "vue-toastification";

import api from "../../../api/index.js";

const toast = useToast();

export default {
  async getJobApplicationList(context, payload = {}) {
    const { jobApplicationService } = api;
    const response = await jobApplicationService.get(payload);

    if (response.status === 200) {
      const { data } = response.data;
      context.commit("setJobApplications", data);
    } else {
      const { data } = response;
      const { errorMessage } = data;
      console.log(errorMessage);
    }
  },
  async getUserAppliedJobList(context, payload = {}) {
    const { jobApplicationService } = api;
    const response = await jobApplicationService.userJobApplicationList(
      payload
    );

    if (response.status === 200) {
      const { data } = response.data;
      context.commit("setAppliedJobs", data);
    } else {
      const { data } = response;
      const { errorMessage } = data;
      console.log(errorMessage);
    }
  },
  async userApplyJob(context, payload) {
    const { jobApplicationService } = api;
    const response = await jobApplicationService.userApplyJob(payload);

    if (response.status === 200) {
      toast.success("Job application successful");
      return true;
    } else {
      const { errorMessage } = response.data;

      if (errorMessage === "Data was found in an existing record")
        toast.error("Job is already applied");
      else toast.error("Failed to apply job");

      return false;
    }
  },
  async updateJobApplication(context, payload) {
    const { jobApplicationService } = api;
    const response = await jobApplicationService.updateJobApplication(payload);

    if (response.status === 200) {
      toast.success("Job application has been successfully updated");
      return true;
    } else {
      const { errorMessage } = response.data;
      console.log(errorMessage);

      toast.error("Failed to update job application");

      return false;
    }
  },
  async jobReferral(context, payload) {
    const { jobApplicationService } = api;
    const response = await jobApplicationService.jobReferral(payload);

    if (response.status === 200) {
      toast.success("Job referral successful");
      return true;
    } else {
      const { errorMessage } = response.data;
      console.log(errorMessage);

      toast.error("Failed to process job referral");

      return false;
    }
  },
};
