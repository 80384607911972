export default {
  user(state) {
    return state.user;
  },
  users(state) {
    return state.users;
  },
  withdrawalRequests(state) {
    return state.withdrawalRequests;
  },
};
