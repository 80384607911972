import axios from "axios";

import store from "../store/index.js";

// Create the axios instance
const axiosInstance = axios.create({});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Modify the request config here, such as adding headers
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = token;
    }

    config.headers["Cache-Control"] = "no-cache";

    return config;
  },
  (error) => {
    // Handle request error
    console.log(`axios.interceptors.request error: ${error}`);
    // return Promise.reject(error);
    return error;
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Modify the response data or handle certain response statuses
    return response;
  },
  (error) => {
    // Handle response error
    const { response } = error;
    const { data } = response;
    const { name, message } = data;

    // Unauthorized user
    if (data.error === "Unauthorized User") {
      store.dispatch("auth/logout");
    }

    // JWT token expired
    if (name === "TokenExpiredError" && message === "jwt expired") {
      store.dispatch("auth/logout");
    }

    // Email not verified
    /* if (data.errorMessage === "Your email are not verified") {
      const currentPath = router.currentRoute.value.path;
      const allowedPaths = ["/", "/verify-email"];
      if (!allowedPaths.includes(currentPath)) {
        router.push("/verify-email");
      }
    } */

    console.log(`axios.interceptors.response error: ${error}`);
    // return Promise.reject(error);
    return response;
  }
);

export default axiosInstance;
